<template>
  <div class="list-form-primary-data padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title padding-left-layout">
            {{ $t('supplier_products.title_form_primary_data') }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table approval-table primary-data list-form"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="listFormDataMergeGrid"
        :grid-columns="listFormDataMergeCol"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :filterColumns="filterColumns"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :labelEmpty="descriptionLabelEmpty"
        :isResizeCustome="false"
        :isHasData="isHasData"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        @onHandleActionTable="onHandleActionTable"
      />
    </div>
    <dialog-popup
      :dialog="dialogPopup"
      :message="dialogMessage"
      @submit="() => checkboxHandler(false)"
      @close="closePopup()"
    />
    <notification-popup
      :isAdmin="true"
      :dialog="dialogNotification"
      :message="messageNotitfication"
      @submit="closeNotificationPopUp"
    />
  </div>
</template>

<script>
import DataTable from '@/components/category/data-table';
import DialogPopup from '@/components/dialogs/question-popup';
import { updateFormPrimaryData } from '@/api/product/emissionsProduct.js'
import { getListFormCfpSupplierApi } from '@/api/product/emissionsProduct';
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import { BLANK_ID, CANCEL_TYPE, ACTION_HEADER_TABLE } from '@/constants/registerData';
import { getScopeCategoryMethodName, showItemName } from '@/utils/primaryDataLinked';
import { mapActions, mapState } from 'vuex';
import * as wjcCore from '@mescius/wijmo';
import { CollectionView, PopupPosition, Tooltip } from '@mescius/wijmo';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import * as wjGrid from '@mescius/wijmo.grid';
import { cancelSubmittedData } from '@/api/newRegisterData';
import { getWidthEditlButton } from '@/utils/multiLanguage';
import { setMinMaxSizeColumns,getWidthByTextContent } from '@/utils/calcTextWidth';
import { toolTipCustom } from '@/utils/tooltipCustom';
import { Clipboard } from '@mescius/wijmo';
import { ORGANIZATIONAL_DIVISION } from '@/constants/registerData';
import { emptyPromise } from '@/concerns/registerData/wijmo.helper';
import notificationPopup from '@/components/dialogs/notification-popup.vue';
import { getWidthByTextHeader } from '@/utils/multiLanguage';

export default {
  name: 'ApprovalPage',
  components: {
    DataTable,
    DialogPopup,
    notificationPopup,
  },
  data() {
    return {
      listFormDataMergeGrid: null,
      flexGrid: null,
      formDataLinkeds: [],
      listFormDataMergeCol: [],
      dialogChangeStatus: false,
      dialogComment: false,
      showTooltip: false,
      layers: [],
      breadCrumb: [
        {
          text: this.$t('supplier_products.hyperlink_primary_data'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.PRIMARY_DATA_MERGE,
        },
        {
          text: this.$t('supplier_products.hyperlink_linked_emissions_data'),
          disabled: false,
          href: ROUTES.PRODUCTS_EMISSION + ROUTES.PRIMARY_DATA_MERGE,
        },
        {
          text: this.$t('supplier_products.title_form_primary_data'),
          disabled: true,
          href: ROUTES.FORM_PRIMARY_DATA_MERGE,
        },
      ],
      commentContents: [],
      dialogNotification: null,
      messageNotitfication: this.$t('popup.popup_keep'),
      filterColumns: [],
      dialogPopup: false,
      commentHistoryList: [],
      historyLogList: [],
      userName: '',
      selectedFilterColumn: null,
      isEmptySource: true,
      dialogMessage: this.$t('primary_data_merge.table_disable_row_data'),
      isEditDataTable: false,
      itemsChangeStatus: {},
      hdrTips: new Tooltip({
        position: PopupPosition.Above,
        showAtMouse: true,
        showDelay: 600,
        cssClass: 'hdr-tip',
      })
    };
  },
  async mounted() {
    if (this.$route.query?.is_allow_cancel) {
      await this.handleApprovalCancleSubmit();
    }
    this.actionUpdateIsLoadingTable(true);
    await this.handleGetListFormPrimaryData();
    this.actionUpdateIsLoadingTable(false);
    this.updateBreadCrumb(this.breadCrumb);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    ...mapActions('registerData', ['updateDataScope', 'updateDataCategory', 'updateDataMonth', 'updateDuration']),
    ...mapActions('newRegisterData', ['updateIsApproved', 'updatePatternId']),
    closeNotificationPopUp() {
      this.dialogNotification = false;
      this.messageNotitfication = null;
    },
    initGrid(grid) {
      this.flexGrid = grid;
      document?.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      grid.pastingCell.addHandler((s, e) => {
        e.cancel = true;
      }); 
      grid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.columnHeaders && colBinding === 'url') {
          if (colBinding === 'url') {
            toolTipCustom(e, 't1', this.$t('primary_data_merge.table_tooltip_url_form_data_merge'), this.hdrTips);
          }
        }
        if (e.panel.cellType === wjGrid.CellType.Cell) {
          const isDisableRow = s.rows[e.row]._data?.status;

          if (colBinding === 'url' && isDisableRow) {
            var oldHTML = e.cell.innerHTML;
            var newDiv = document.createElement('div');
            newDiv.className = 'copy-icon';
            newDiv.addEventListener('click', function () {
              Clipboard.copy(oldHTML);
            });
            e.cell.innerHTML = `<div class="url-link">${oldHTML}</div>`;
            e.cell.appendChild(newDiv);
          }

          if (!isDisableRow || colBinding === 'initial_password') {
            e.cell.classList.add('disabled_row');
            if (colBinding === 'unique_id') {
              e.cell.classList.add('hide-edit');
            }
          }
        }
      });
      grid.beginningEdit.addHandler((sender, event) => {
        let column = sender.columns[event.col];
        let rowValue = sender.rows[event.row]._data;

        const listColumnNotDisable = ['unique_id'];
        if (!listColumnNotDisable.includes(column.binding)) {
          event.cancel = true;
        }
        if (column.binding === 'status') {
          this.handleCheckBox(rowValue);
        }
      });
      grid.pasting.addHandler((s, e) => {
        const { col, col2, row, row2 } = e.range;
        const pastingData = e.data.split('\n');
        let count = 0;
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
            const colBinding = s.columns[colIndex]?.binding;
            if (colBinding === 'status') {
              const currentData = s.getCellData(rowIndex, colIndex, false).toString();
              const pastingValue = pastingData[count]?.split('\t')[pastingData[count]?.split('\t').length - 1];
              if (pastingValue !== currentData) {
                count += 1;
                this.addDataHasChangeStatus(s.rows[rowIndex]?.dataItem);
                this.isEditDataTable = true;
              }
            }
          }
        }
      });
      grid.onSelectionChanged(null);
    },
    async handleGetListFormPrimaryData() {
      const formDataLinkedsRes = await getListFormCfpSupplierApi();
      this.layers = formDataLinkedsRes?.layer
      .map((layer) => {
        let binding = `layer_${layer.layer_index}`;
        if (layer.layer_index === 1) {
          binding = 'business_name';
        } else if (layer.layer_index === 2) {
          binding = 'country';
        }
        return {
          ...layer,
          binding,
        };
      })
      .sort((a, b) => a.layer_index - b.layer_index);
      if (formDataLinkedsRes.data.length > 0) {
        this.formDataLinkeds = formDataLinkedsRes.data.map((formDataLinkedItem) => {
          const users = formDataLinkedItem.credential_supplier;
          return {
            ...formDataLinkedItem,
            ...formDataLinkedItem.cfp_product?.branch,
            url: window.location.origin + '/auth/login?primary_uuid=' + formDataLinkedItem.uuid,
            status: formDataLinkedItem.status,
            organizational_division:
              ORGANIZATIONAL_DIVISION[formDataLinkedItem.cfp_product?.branch?.organizational_division - 1],
            id: formDataLinkedItem.id,
            email: users?.user?.email,
            initial_password: users?.initial_password,
            cfp_product: formDataLinkedItem?.cfp_product?.name,
            lot_number: formDataLinkedItem?.cfp_product?.lot_number,
            cfp_boundary: formDataLinkedItem?.cfp_boundary?.name,
            cfp_process: formDataLinkedItem?.cfp_process?.name,
          };
        });
      }
      this.defineTable();
    },
    onHandleActionTable() {
      const dataChangeStatus = Object.values(this.itemsChangeStatus).map(i => ({id: i.id, status: i.status ? 1: 0}));
      const editedPromise = Object.values(dataChangeStatus).length
        ? updateFormPrimaryData({ data: Object.values(dataChangeStatus) })
            .then((response) => {
              this.dialogNotification = true;
              this.messageNotitfication = this.$t('popup.popup_keep');
              this.itemsChangeStatus = {};
              this.isEditDataTable = false;
            })
            .catch((error) => {
              console.warn(error);
            })
        : emptyPromise();
      Promise.all([editedPromise]);
    },
    handleCheckBox(value) {
      if (value.status) {
        this.dialogPopup = true;
        this.dialogCancelBtn = true;
      } else {
        this.checkboxHandler(true);
      }
    },
    checkboxHandler(check) {
      this.isEditDataTable = true;
      this.listFormDataMergeGrid.beginUpdate();
      this.listFormDataMergeGrid.currentItem.status = check;
      this.listFormDataMergeGrid.endUpdate();
      this.addDataHasChangeStatus(this.listFormDataMergeGrid.currentItem)
      this.closePopup();
    },
    addDataHasChangeStatus(currentItem) {
      if(this.itemsChangeStatus[currentItem.id]) {
        delete this.itemsChangeStatus[currentItem.id]
        return
      }

      this.itemsChangeStatus[currentItem.id] = currentItem
      if(Object.values(this.itemsChangeStatus)) {
        this.isChangeStatus = true
      }
    },
    closePopup() {
      this.dialogPopup = false;
      this.dialogCancelBtn = true;
      this.isHasAdmin = true;
      this.dialogMessage = this.$t('primary_data_merge.table_disable_row_data');
    },
    initialView() {
      if (this.listFormDataMergeGrid) {
        this.formDataLinkeds = this.listFormDataMergeGrid.items.filter(
          (formDataLinkedItem) => formDataLinkedItem.id && formDataLinkedItem.id !== BLANK_ID,
        );
      }
      this.listFormDataMergeGrid = new CollectionView([...this.formDataLinkeds], {
        trackChanges: true,
      });
      this.flexGrid.columnGroups = this.getApprovalColumns();
      this.listFormDataMergeGrid.currentItem = null;
      setMinMaxSizeColumns(this.flexGrid, this.formDataLinkeds);
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    getApprovalColumns() {
      const mapLayers = this.layers.map((layer) => ({
        header: layer.layer_name,
        binding: layer.binding,
        minWidth: 114,
        maxWidth: 980,
        allowSorting: false,
        multiLine: true,
        wordWrap: true,
        cssClassAll: 'readOnly-Disabled',
      }));
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'unique_id',
          minWidth: getWidthEditlButton(),
          maxWidth: getWidthEditlButton(),
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClass: 'btn-db',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('primary_data_merge.table_button_edit_form'),
            click: (event, context) => this.onIdClicking(context),
          }),
          cssClassAll: 'height34 approval-cell_custom hide-filter',
        },
        {
          header: this.$t('primary_data_merge.table_title_form_data_merge'),
          binding: 'title',
          minWidth: 298,
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'readOnly-Disabled',
        },
        {
          header: this.$t('primary_data_merge.table_url_form_data_merge'),
          binding: 'url',
          // minWidth: 400,
          minWidth: 400,
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'readOnly-Disabled',
        },
        {
          header: this.$t('primary_data_merge.table_supplier_email_id'),
          binding: 'email',
          minWidth: 227,
          maxWidth: 300,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'readOnly-Disabled',
        },
        {
          header: this.$t('primary_data_merge.table_supplier_password'),
          binding: 'initial_password',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_supplier_password')),
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('primary_data_merge.table_product_name'),
          binding: 'cfp_product',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_product_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'readOnly-Disabled',
        },
        {
          header: this.$t('primary_data_merge.table_lot_no'),
          binding: 'lot_number',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_lot_no')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'readOnly-Disabled',
        },
        {
          header: this.$t('primary_data_merge.table_boundary_name'),
          binding: 'cfp_boundary',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_boundary_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'readOnly-Disabled',
        },
        {
          header: this.$t('primary_data_merge.table_process_name'),
          binding: 'cfp_process',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_process_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'readOnly-Disabled',
        },
        {
          header: this.$t('b_list_all.table_organizational_information'),
          align: 'center',
          collapseTo: 'organizational_division',
          columns: [
            {
              header: this.$t('primary_data_merge.table_organizational_division'),
              binding: 'organizational_division',
              minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_organizational_division')),
              maxWidth: 980,
              allowSorting: false,
              isRequired: false,
              multiLine: true,
              wordWrap: true,
              cssClassAll: 'readOnly-Disabled',
            },
            {
              header: this.$t('primary_data_merge.table_company_name'),
              binding: 'company_name',
              minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_company_name')),
              maxWidth: 980,
              allowSorting: false,
              isRequired: false,
              multiLine: true,
              wordWrap: true,
              cssClassAll: 'readOnly-Disabled',
            },
            ...mapLayers,
          ],
        },
        {
          header: this.$t('primary_data_merge.table_checked'),
          binding: 'status',
          allowSorting: false,
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_checked')),
          dataType: 'Boolean',
          wordWrap: true,
          cssClassAll: '',
        },
      ];
    },
    async onIdClicking(rowData) {
      this.$router.push({name: 'EditFormCfp', params: {id: rowData.item.id}})
    },
    defineTable() {
      this.listFormDataMergeCol = this.getApprovalColumns();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
      this.isEmptySource = this.formDataLinkeds.length <= 0;
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;
      if (!flexgrid) {
        return;
      }
    },
    async handleApprovalCancleSubmit() {
      try {
        const payload = {
          action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          dataWorkflow: {
            id: parseInt(this.$route.query.workflow_data_id),
            comment: '',
          },
          is_allow_cancel: CANCEL_TYPE.ACCEPT,
        };
        await cancelSubmittedData(payload);
        this.$router.replace({ path: '/approval' });
      } catch (error) {
        this.$router.replace({ path: '/approval' });
      }
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),
    descriptionLabelEmpty() {
      return this.$t('primary_data_merge.description_label_empty');
    },
    isHasData() {
      return this.isEditDataTable;
    },
  },
};
</script>
<style lang="scss">
.wj-flexgrid .readOnly-Disabled.wj-cell[aria-readonly] {
  background: $monoOffWhite !important;
  color: $monoBlack !important;
}
.list-form-primary-data {
  .wj-flexgrid {
    .wj-cells {
      .wj-state-active:not(.wj-state-multi-selected) {
        background: $monoOffWhite !important;
      }
      .wj-row {
        .disabled_row {
          background: $monoOffWhite !important;
          color: $monoMid !important;
          &.hide-edit {
            button {
              display: none;
            }
          }
          &.wj-state-multi-selected {
            background: $blueExtraLight !important;
          }
        }
      }
    }
  }
}
.primary-data {
  &.list-form {
    .url-link {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 25px;
    }
    .copy-icon {
      position: absolute;
      right: -2px;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-image: url('../../../../assets/icons/copy_url.svg');
      &:hover {
        cursor: pointer;
        background-image: url('../../../../assets/icons/copy_url_active.svg');
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import './../styles/index.scss';
</style>
